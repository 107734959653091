// extracted by mini-css-extract-plugin
export var ArtistDescription = "Toxic-module--ArtistDescription--O6SEr";
export var ArtistInfos = "Toxic-module--ArtistInfos--ueTX5";
export var ButtonWrapper = "Toxic-module--ButtonWrapper --2HKqW";
export var CardWrapper = "Toxic-module--CardWrapper--ObYHS";
export var CarrouselWrapper2 = "Toxic-module--CarrouselWrapper2--JL2xa";
export var Citations = "Toxic-module--Citations--eqmHE";
export var DescriptionWrapper = "Toxic-module--DescriptionWrapper--hTBfy";
export var ImageWrapper = "Toxic-module--ImageWrapper--nnUlt";
export var LinkWrapper = "Toxic-module--LinkWrapper--5TYnd";
export var MobileProtrait = "Toxic-module--MobileProtrait--Q0S+U";
export var More = "Toxic-module--More--aFC4u";
export var MoreButton = "Toxic-module--MoreButton--Yzj7f";
export var NameWrapper = "Toxic-module--NameWrapper--nHmHE";
export var PdpWrapper = "Toxic-module--PdpWrapper--NeNhv";
export var PhotosWrapper = "Toxic-module--PhotosWrapper--+zX7A";
export var ProfilWrapper = "Toxic-module--ProfilWrapper--fkhsy";
export var TitleWrapper = "Toxic-module--TitleWrapper--Zi-J1";
export var Wrapper = "Toxic-module--Wrapper--1qnIg";